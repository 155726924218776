<template>
  <div class="missionFraisComponent">

    <CModal
      :show.sync="isFraisAdding"
      :no-close-on-backdrop="true"
      title="Ajouter un frais mission"
      size="lg"
      color="dark"
    >
      <CForm>
        <CInput
          label="Description"
          placeholder="Décrivez le type de frais"
          v-model="newFraisDescription" type="text"
          @input="$v.newFraisDescription.$touch()"
          :isValid="$v.newFraisDescription.$dirty ? !$v.newFraisDescription.$error : null"
        >
        </CInput>
        <CRow>
          <CCol>
            <label>Date <small class="text-danger mb-4" v-if="$v.newFraisDateFormat.$invalid ">Veuillez saisir une date valide antérieure à aujourd'hui</small></label>
            <CRow>
              <CCol sm="3">
                <CSelect
                  :value.sync="newFraisDateDay"
                  :options='daysArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  :value.sync="newFraisDateMonth"
                  :options='monthsArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="3">
                <CSelect
                  :value.sync="newFraisDateYear"
                  :options='yearsArrayForm'
                  custom
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CInput
          placeholder="Montant en €"
          label="Montant total"
          type="number"
          v-model="newFraisMontant"
          @input="$v.newFraisMontant.$touch()"
          :isValid="$v.newFraisMontant.$dirty ? !$v.newFraisMontant.$error : null"
          min="0" max="100000000" step=".01" @blur="newFraisMontant = parseFloat(newFraisMontant).toFixed(2)">
        </CInput>

        <b-form-group class="mt-3 dragdrop">
          <label for="new-frais-file-input">Votre justificatif (facultatif)</label>
          <b-form-file
            class="text-truncate"
            browse-text='Parcourir'
            required
            v-model="newFraisFile"
            id="new-frais-file-input"
            name="new-frais-file-input"
            placeholder="Sélectionner ou glisser-déposer votre fichier"
            drop-placeholder="Déposez votre fichier ici"
            @input="$v.newFraisFile.$touch()"
            :state="$v.newFraisFile.$dirty ? !$v.newFraisFile.$error : null"
          >
          </b-form-file>

          <CButton
            class="mt-2"
            v-if="newFraisFile"
            @click="newFraisFile = null"
            color="outline-danger"
            shape="pill"
            size="sm"
            >
            <CIcon name="cil-x-circle" /> Supprimer votre document
          </CButton>
          <div class="text-danger mt-2" v-if="!$v.newFraisFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 3 MB.</div>
          <div class="text-danger mt-2" v-if="!$v.newFraisFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg, png, xls ou doc</div>
          <div class="text-danger mt-2" v-if="!$v.newFraisFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
          <div class="text-danger mt-2" v-if="!$v.newFraisFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
        </b-form-group>


        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="newFraisButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.newFraisDateFormat.$invalid || $v.newFraisMontant.$invalid || $v.newFraisDescription.$invalid || $v.newFraisFile.$invalid || addFraisInProcess"
              @click='addFrais'>
              <CSpinner size="sm" label="update task spinner" v-if="addFraisInProcess"></CSpinner>
              {{ newFraisButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isFraisAdding = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- MODIFY TASK -->
    <CModal
      :show.sync="isFraisModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre frais client"
      size="lg"
      color="dark"
    >
      <CForm>
        <CInput
          label="Description"
          v-model="modifyFraisDescription" type="text"
          @input="$v.modifyFraisDescription.$touch()"
          :isValid="$v.modifyFraisDescription.$dirty ? !$v.modifyFraisDescription.$error : null"
        >
        </CInput>
        <CRow>
          <CCol>
            <label>Date <small class="text-danger mb-4" v-if="$v.modifyFraisDateFormat.$invalid ">Veuillez saisir une date valide antérieure à aujourd'hui</small></label>
            <CRow>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyFraisDateDay"
                  :options='daysArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  :value.sync="modifyFraisDateMonth"
                  :options='monthsArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyFraisDateYear"
                  :options='yearsArrayForm'
                  custom
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>

        <CInput
          placeholder="Prix HT"
          label="Prix (en € HT)"
          type="number"
          v-model="modifyFraisMontant"
          @input="$v.modifyFraisMontant.$touch()"
          :isValid="$v.modifyFraisMontant.$dirty ? !$v.modifyFraisMontant.$error : null"
          min="0" max="100000000" step=".01" @blur="modifyFraisMontant = parseFloat(modifyFraisMontant).toFixed(2)">
        </CInput>

        <b-form-group class="mt-3 dragdrop">
          <label v-if="modifyFraisFileName"> Votre document actuel : <a href="#" @click="downloadFraisFile(modifyFraisId, modifyFraisFileName)">
            {{modifyFraisFileName}}</a></label>
          <label v-else>Aucun justificatif chargé</label><br>
          <b-form-file
            class="text-truncate"
            browse-text='Parcourir'
            required
            v-model="modifyFraisFile"
            id="new-frais-file-input"
            name="new-frais-file-input"
            placeholder="Sélectionner ou glisser-déposer votre fichier"
            drop-placeholder="Déposez votre fichier ici"
            @input="$v.modifyFraisFile.$touch()"
            :state="$v.modifyFraisFile.$dirty ? !$v.modifyFraisFile.$error : null"
          ></b-form-file>
          <CButton
            class="mt-2"
            v-if="modifyFraisFile"
            @click="modifyFraisFile = ''"
            color="outline-danger"
            shape="pill"
            size="sm"
            >
            <CIcon name="cil-x-circle" /> Supprimer votre document
          </CButton>
          <div class="text-danger mt-2" v-if="!$v.modifyFraisFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 3 MB.</div>
          <div class="text-danger mt-2" v-if="!$v.modifyFraisFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg, png, xls ou doc</div>
          <div class="text-danger mt-2" v-if="!$v.modifyFraisFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
          <div class="text-danger mt-2" v-if="!$v.modifyFraisFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
        </b-form-group>

        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyFraisButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyFraisDateFormat.$invalid || $v.modifyFraisMontant.$invalid || $v.modifyFraisDescription.$invalid || $v.modifyFraisFile.$invalid || modifyFraisInProcess"
              @click='modifyFrais'>
              <CSpinner size="sm" label="update task spinner" v-if="modifyFraisInProcess"></CSpinner>
              {{ modifyFraisButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isFraisModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow class="mt-3">
      <CCol>
        <CDataTable
          headVariant="light"
          :items="fraisRender"
          :fields="fraisRenderFields"
          :noItemsView='{ noResults: "Aucun frais", noItems: "Aucun frais" }'
        >

        <template #date="{item}">
          <td class="text-center">
           {{$dayjs(item.date).format('DD/MM/YYYY')}}
          </td>
        </template>

        <template #description="{item}">
          <td>
           <small>{{item.description}}</small>
          </td>
        </template>

        <template #montant="{item}">
          <td class="text-center">
            {{formatThousandSeparatorNumber(parseFloat(item.montant).toFixed(2))}} €
          </td>
        </template>

        <template #actions="{item}">
          <td>
            <CButton
              :disabled="!item.file"
              class="ml-2"
              square
              size="sm"
              @click="downloadFraisFile(item.id, item.file_name)">
              <CIcon v-if="item.file" class="text-dark" name="cil-cloud-download"/>
              <CIcon v-else class="text-white" name="cil-cloud-download"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              @click="showModifyFrais(item)">
              <CIcon class="text-primary" name="cil-pencil"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              :disabled="deleteFraisInProcess"
              @click="deleteFrais(item.id)">
              <CIcon class="text-danger" name="cil-trash"/>
            </CButton>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun frais enregistré
            </CCol>
          </CRow>
        </template>
        </CDataTable>

        <CButton
          v-if="connectedUserInMissionCollaborateurs"
          @click="isFraisAdding = true"
          shape="pill" block class="px-4"
          color="outline-success">
          <CIcon name="cil-plus"/> Ajouter un frais associé à la mission
        </CButton>

        <CRow v-else>
          <CCol class="text-center">
            <strong class="text-danger"> Vous devez être affecté à cette mission pour y affecter des frais </strong>
          </CCol>
        </CRow>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import { APIUserConnected } from '@/api/APIUserConnected'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue, maxValue} from 'vuelidate/lib/validators'
import { file_size_validation, file_extension_validation } from '@/validators/validators'
//import { fileOperations } from '@/mixins/utilMixins'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';

import 'vue-loading-overlay/dist/vue-loading.css';

import { isValidDateBeforeToday } from '@/validators/validators'

const apiUserConnected = new APIUserConnected()

export default {
  name: 'missionFraisComponent',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    renderMixins
  ],
  props: {
    missionPk: String,
    connectedUserInMissionCollaborateurs: Boolean
  },
  data: function () {
    const today = this.$dayjs()
    const todayYear = today.format('YYYY')
    const todayMonth = today.format('MM')
    const todayDay = today.format('DD')
    return {

      // ------------- FRAIS -----------
      frais: [],
      fraisRender: [],
      fraisRenderFields: [
        { key: "date", label: "Date", _style: "min-width: 200px;"},
        { key: "description", label: "Description", _style: "min-width: 200px;"},
        { key: "montant", label: "Montant", _style: "min-width: 150px;"},
        { key: "collaborateur_full_name", label: "Ajouté par", _style: "min-width: 150px;"},
        { key: "actions", label: "Actions", _style: "min-width: 100px;"},
      ],
      isAllFraisLoading: false,

      monthsArrayForm: [
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],


      // ------------ ADD FRAIS --------
      isFraisAdding: false,

      newFraisDateDay: todayDay,
      newFraisDateMonth: todayMonth,
      newFraisDateYear: todayYear,
      newFraisDescription: '',
      newFraisMontant: '',
      newFraisFile: null,

      newFraisButtonText: 'Ajouter',
      newFraisButtonStyle: 'outline-primary',
      addFraisInProcess: false,

      // ---------- MODIFY FRAIS ---
      isFraisModifying: false,

      modifyFraisId: 0,
      modifyFraisDateDay: '01',
      modifyFraisDateMonth: '01',
      modifyFraisDateYear: '2020',
      modifyFraisDescription: '',
      modifyFraisMontant: '',
      modifyFraisFile: null,
      modifyFraisFileName: '',

      modifyFraisButtonText: 'Modifier votre frais mission',
      modifyFraisButtonStyle: 'outline-primary',
      modifyFraisInProcess: false,

      // ---------- DELETE FRAIS ---
      deleteFraisButtonText: 'Supprimer',
      deleteFraisButtonStyle: 'outline-danger',
      deleteFraisInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllFraisLoading) {
        return true
      }
      return false
    },
    // ---- Date
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },

    yearsArrayForm () {
      var today = this.$dayjs()
      var maxYear = today.format('YYYY')

      var years = []
      for (var i=1990; i<=maxYear; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },

    newFraisDateFormat () {
      return this.newFraisDateYear + '-' + this.newFraisDateMonth + '-' + this.newFraisDateDay
    },
    modifyFraisDateFormat () {
      return this.modifyFraisDateYear + '-' + this.modifyFraisDateMonth + '-' + this.modifyFraisDateDay
    },

  },

  validations: {

    newFraisDateFormat: {
      required,
      validDateBeforeToday(value) {
        return isValidDateBeforeToday(value)
      }
    },
    newFraisDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    newFraisMontant: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(1000000)
    },
    newFraisFile: {
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },


    modifyFraisDateFormat: {
      required,
      validDateBeforeToday(value) {
        return isValidDateBeforeToday(value)
      }
    },
    modifyFraisDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },

    modifyFraisMontant: {
      required,
      minValue: minValue(1),
      maxValue: maxValue(1000000)
    },
    modifyFraisFile: {
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getMissionFrais()
  },

  watch: {
    frais: function (resultFrais) {
      if (resultFrais.length == 0) {
        this.fraisRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < resultFrais.length; i++) {
          final_array.push(
            {
              'id': resultFrais[i].id,
              'mission_id': resultFrais[i].mission,
              'mission_name': resultFrais[i].mission_name,
              'date': resultFrais[i].date,
              'montant': resultFrais[i].montant,
              'description': resultFrais[i].description,
              'collaborateur_full_name': resultFrais[i].collaborateur_full_name,
              'file_name': resultFrais[i].file_name,
              'file': resultFrais[i].file
            }
          )
        }
        this.fraisRender = final_array
      }
    },
  },

  methods: {

    // -------------- GETTERS -----------------------

    getMissionFrais () {
      this.isAllFraisLoading = true
      apiUserConnected.getMissionFrais(this.token, this.missionPk)
      .then((result) => {
        this.frais = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllFraisLoading = false
      })
    },

    // ------------ ADD FRAIS --------------
    addFrais () {
      this.addFraisInProcess = true
      this.addFraisButtonText = "Ajout en cours"
      this.addFraisButtonStyle = 'secondary'
      apiUserConnected.addFrais(this.token,
        this.missionPk, this.newFraisDateFormat,
        this.newFraisMontant, this.newFraisDescription, this.newFraisFile)
      .then(() => {
        this.getMissionFrais()
        this.newFraisMontant = ''
        this.newFraisDescription = ''
        this.newFraisFile = null
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addFraisInProcess = false
        this.addFraisButtonText = "Ajouter"
        this.addFraisButtonStyle = 'outline-dark'
        this.isFraisAdding = false
      })
    },


    // ---------- MODIFY FRAIS ------------
    showModifyFrais(item) {
      this.modifyFraisId = item.id
      this.modifyFraisMontant = item.montant
      this.modifyFraisDescription = item.description
      var date = this.$dayjs(item.date, 'YYYY-MM-DD')
      this.modifyFraisDateYear = date.format('YYYY')
      this.modifyFraisDateMonth = date.format('MM')
      this.modifyFraisDateDay = date.format('DD')
      this.modifyFraisFileName = item.file_name
      this.isFraisModifying = true
    },
    modifyFrais () {
      this.modifyFraisButtonText = 'Modification en cours'
      this.modifyFraisButtonStyle = 'secondary'
      this.modifyFraisInProcess = true
      apiUserConnected.modifyFrais(this.token,
        this.modifyFraisId, this.missionPk, this.modifyFraisDateFormat,
        this.modifyFraisMontant, this.modifyFraisDescription, this.modifyFraisFile)
      .then(() => {
        this.getMissionFrais()
        this.modifyFraisFile = null
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isFraisModifying = false
        this.modifyFraisButtonText = 'Modifier votre fais mission'
        this.modifyFraisButtonStyle = 'outline-primary'
        this.modifyFraisInProcess = false
      })
    },

    // --------------- DELETE FRAIS
    deleteFrais (frais_id) {
      this.deleteFraisButtonText = 'Suppression en cours'
      this.deleteFraisButtonStyle = 'secondary'
      this.deleteFraisInProcess = true
      apiUserConnected.deleteFrais(this.token, frais_id)
      .then(() => {
        this.getMissionFrais()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteFraisButtonText = 'Supprimer'
        this.deleteFraisButtonStyle = 'outline-danger'
        this.deleteFraisInProcess = false
      })
    },

    downloadFraisFile (document_id, document_name) {
      apiUserConnected.downloadFraisFile(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }



}
</script>

<style>
.dragdrop .custom-file,
.dragdrop .custom-file-input {
  height: 100px;
  cursor: pointer;
}

.dragdrop .custom-file-label {
  border: 0;
  border: 2px dotted;
  height: 100px;
  line-height: 90px;
  text-align: center;
  padding: 0;
}

.dragdrop .custom-file:hover .custom-file-label {
  background: #321fdb;
  color: #fff;
}

.dragdrop .custom-file-label::after {
  display: none;
}
</style>
