<template>
  <div class="MissionProfile">

    <!-- REMOVE COLLABORATEUR MODAL -->
    <CModal
      :show.sync="isCollaborateurRemoving"
      :no-close-on-backdrop="true"
      title="Supprimer ce collaborateur"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          <p>
            Attention, si vous supprimez {{ collaborateurRemovingFullName }} de la mission, il sera également <strong>supprimé de toutes
            les priorités missions auxquelles il a été associé et ne pourra plus faire de modifications.</strong>
          </p>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol class="text-center">
          <CButton
            :color="removeCollaborateurButtonStyle"
            shape="pill" block class="px-4"
            @click='removeCollaborateur'
            :disabled="removeCollaborateurInProcess">
            <CSpinner size="sm" label="activate user spinner" v-if="removeCollaborateurInProcess"></CSpinner>
            {{ removeCollaborateurButtonText }}
          </CButton>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="isCollaborateurRemoving = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- REMOVE COLLABORATEUR IS REFERENT MODAL -->
    <CModal
      :show.sync="isCollaborateurRemovingReferent"
      :no-close-on-backdrop="true"
      title="Impossible de supprimer ce collaborateur"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          <strong>⛔ {{ collaborateurRemovingFullName }} est le référent de la mission</strong>, il n'est donc pas possible de le supprimer des
          collaborateurs associés à cette mission.
          Pour pouvoir le supprimer, vous pouvez <router-link
              :to="{ name: 'Modifier votre mission', params: {id:mission.id} }">
              modifier la mission
            </router-link> et définir un autre référent.
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="isCollaborateurRemovingReferent = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isMissionActivated"
      :no-close-on-backdrop="true"
      title="Mission réactivée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre mission a bien été réactivée 🥳
      </p>
      <p>
        Tous les collaborateurs associés la verront dorénavant dans leur liste de missions et pourront
        créer des priorités, factures, etc.
      </p>
      <template #footer>
        <CButton @click="isMissionActivated = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isDocumentAskingCreated"
      :no-close-on-backdrop="true"
      title="Demande de documents réalisée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre demande de documents a bien été créée 👍
      </p>
      <p>
        Votre client peut directement se connecter à son espace en ligne pour charger ses documents.
      </p>
      <template #footer>
        <CButton @click="isDocumentAskingCreated = false" color="dark">Fermer</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow class="mb-3" v-if="isManager">
      <CCol sm="6">
        <h1> {{mission.client.name}} - {{mission.name}} </h1>
      </CCol>
      <CCol v-if="mission.is_active" class="text-right align-self-center" sm="6">
        <CButton
          @click="goToMissionPage"
          size="sm"
          shape="pill"
          color="outline-primary">
            <CIcon name="cil-pencil"/> Modifier ou archiver cette mission
        </CButton>
      </CCol>
    </CRow>

    <CRow class="mb-3" v-else>
      <CCol>
        <h1> {{mission.client.name}} - {{mission.name}} </h1>
      </CCol>
    </CRow>

    <CRow class="font-dosis mt-3">
      <CCol xl="3">
        <CWidgetIcon
          text="priorités en cours"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{priorities.length}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3">
        <CWidgetIcon
          text="demandes de documents en cours"
          color="info"
          :icon-padding="false"
        >
          <strong>{{documentAskings.length}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3" v-if="isManager">
        <CWidgetIcon
          text="factures à payer"
          color="danger"
          :icon-padding="false"
        >
          <strong>{{nbFacturesToPay}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol xl="3" v-if="isManager">
        <CWidgetIcon
          text="reste à payer"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{formatThousandSeparatorNumber(parseFloat(resteAPayer).toFixed(2))}} €</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>

    <CCard v-if="!mission.is_active">
      <CCardHeader>
        <slot name="header">
          <strong>Votre mission a été archivée</strong>
        </slot>
      </CCardHeader>

      <CCardBody v-if="isManager">
        <CButton
          :color="activateMissionButtonStyle"
          shape="pill" block class="px-4" size="lg"
          @click='activateMission'
          :disabled="activateMissionInProcess">
          <CSpinner size="sm" label="archive client spinner" v-if="activateMissionInProcess"></CSpinner>
          {{ activateMissionButtonText }}
        </CButton>
      </CCardBody>

      <CCardBody v-else>
        <p>
          Vous pouvez demander à un manager Eclerk de réactiver cette mission
        </p>
      </CCardBody>
    </CCard>

    <CRow class="mb-2">
      <CCol sm="6">
        <span> Client : </span>
        <router-link
          :to="'/client-details/' + mission.client.id">
          <strong class="text-dark"> {{ mission.client.name }} </strong>
        </router-link> <small v-if="mission.client.is_different_payeur"> (payeur : {{mission.client.payeur_name}}) </small><br>
        <em>
          <span v-if="mission.client.phone_number">{{mission.client.phone_number}}</span>
          <span v-if="mission.client.email">/ {{ mission.client.email}}</span>
        </em>
        <div v-if="mission.contact" class="mt-2">
          <span> Contact associé : </span>
          <router-link
            :to="'/contact-details/' + mission.contact.id">
            <strong class="text-dark"> {{ mission.contact.full_name }} </strong>
          </router-link><br>
          <em>{{mission.contact.phone_number}} / {{ mission.contact.email}} </em>
        </div>
      </CCol>
      <CCol sm="6">
        <span>Partie adverse : </span>
        <strong v-if="mission.contre_client_name">{{mission.contre_client_name}}</strong> <em v-else> Non renseigné</em><br>
        <em v-if="mission.contre_client_siren && mission.contre_client_is_firm">
          <span >{{mission.contre_client_siren}}</span>
        </em>

        <div class="mt-2">
          <span> Avocat de la partie adverse : </span>
          <strong v-if="mission.contre_avocat_name">{{mission.contre_avocat_name}}</strong> <em v-else> Non renseigné</em><br>
          <em>{{mission.contre_avocat_email}}  {{ mission.contre_avocat_phone_number}} </em>
        </div>
      </CCol>
    </CRow>
    <CRow v-if="isManager">
      <CCol>
        <CButton shape="pill" color="outline-primary" @click="goToReportingPage">
          <CIcon name="cil-clock" /> Voir le reporting associé à cette mission
        </CButton>

        <CButton class="ml-0 ml-md-3 mt-2 mt-md-0" v-if="!mission.convention_pk" shape="pill" color="outline-primary" @click="createConventionHonoraires">
          <CIcon name="cil-file" /> Générer la convention d'honoraires
        </CButton>

        <CButton class="ml-0 ml-md-3 mt-2 mt-md-0" v-else shape="pill" color="outline-primary" @click="goToConventionsHonoraires">
          <CIcon name="cil-file" /> Editer la convention d'honoraires
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardHeader
        @click="cardDescriptionCollapse = !cardDescriptionCollapse"
        style="padding-bottom: 15px !important; cursor: pointer;"
      >
        <strong>💬 Description de la mission</strong>
        <div class="card-header-actions">
          <CIcon v-if="!cardDescriptionCollapse" name="cil-arrow-circle-bottom" size="lg"/>
          <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
        </div>
      </CCardHeader>
      <CCollapse :show="cardDescriptionCollapse">
        <CCardBody>
          <CRow>
            <CCol sm="6">
              Mission facturée au client :
              <strong v-if="mission.is_billed"> Oui </strong>
              <strong v-else> Non </strong>
            </CCol>
            <CCol v-if="mission.is_billed" sm="6">
              <span> Type de facturation : </span>
              <strong v-if="mission.is_honoraires"> Honoraires</strong>
              <strong v-if="mission.is_honoraires_resultats"> Honoraires de résultats</strong>
              <strong v-if="mission.is_regie_plafonnee"> Régie plafonnée </strong>
              <strong v-if="mission.is_forfait"> Forfait </strong>
              <strong v-if="mission.is_aj"> Aide juridictionnelle </strong>
            </CCol>
          </CRow>
          <CRow v-if="isManager">
            <CCol v-if="mission.is_honoraires || mission.is_regie_plafonnee || mission.is_forfait" sm="6">
              <span> Montant total (HT) : </span>
              <strong v-if="mission.montant_ht"> {{formatThousandSeparatorNumber(parseFloat(mission.montant_ht).toFixed(2))}} € </strong>
              <strong v-else> Non renseigné </strong>
            </CCol>

            <CCol v-if="mission.is_honoraires_resultats" sm="6">
              <span> Pourcentage de résultats : </span>
              <strong v-if="mission.resultats_percentage"> {{formatThousandSeparatorNumber(parseFloat(mission.resultats_percentage).toFixed(2))}} % </strong>
              <strong v-else> Non renseigné </strong>
            </CCol>
          </CRow>
          <CRow class="mt-2">
            <CCol sm="6">
              <span> Date de début de la mission : </span>
              <strong> {{$dayjs(mission.starting_date).format('DD/MM/YYYY')}}</strong>
            </CCol>
            <CCol sm="6">
              <span> Date de fin de mission : </span>
              <strong v-if="mission.ending_date"> {{$dayjs(mission.ending_date).format('DD/MM/YYYY')}}</strong>
              <strong v-else> Non renseignée </strong>
            </CCol>
          </CRow>
          <CRow v-if="mission.rg_number" class="mt-2">
            <CCol>
              <span> Numéro RG : </span> <em> {{mission.rg_number}}</em>
            </CCol>
          </CRow>
          <CRow class="mt-2">
            <CCol>
              <span> Description : </span> <em> {{mission.description}}</em>
            </CCol>
          </CRow>
          <CRow class="mt-2" v-if="mission.is_paiement_alert">
            <CCol sm="8" class="text-danger">
               <CIcon name="cil-bell" class="mr-2"></CIcon>
               <strong>Attention, une alerte de paiement a été levée pour cette mission. </strong><br>
            </CCol>
            <CCol v-if="connectedUserInMissionCollaborateurs" sm="4">
              <CButton
               @click="removeMissionPaiementAlert"
               shape="pill" block class="px-4"
               :disabled="!mission.is_active"
               size="sm" color="outline-dark">
               Supprimer l'alerte
             </CButton>
            </CCol>
          </CRow>
          </CCardBody>
        </CCollapse>
    </CCard>


    <CCard class="mt-3">
      <CCardHeader
        @click="cardCollaborateurCollapse = !cardCollaborateurCollapse"
        style="padding-bottom: 15px !important; cursor: pointer;"
      >
        <strong>🧑 Collaborateurs associés à la mission</strong>
        <div class="card-header-actions">
          <CIcon v-if="!cardCollaborateurCollapse" name="cil-arrow-circle-bottom" size="lg"/>
          <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
        </div>
      </CCardHeader>
      <CCollapse :show="cardCollaborateurCollapse">
        <CCardBody>
          <span> Référent de la mission :</span> <strong>{{mission.referent.full_name}}</strong>
            <CDataTable
              class="mt-3 cursor-table"
              hover
              :items="missionCollaborateursRender"
              :fields="missionCollaborateursRenderFields"
              @row-clicked="clickOnMissionCollaborateur"
            >
            <template #phone_number="{item}">
              <td v-if="item.phone_number">
                +{{returnPhoneDial(item.phone_country_code)}} {{item.phone_number}}
              </td>
              <td v-else>
                <em>Non renseigné</em>
              </td>
            </template>
            </CDataTable>
            <CRow v-if="isManager">
              <CCol>
              <vue-bootstrap-typeahead
                class="mt-1"
                v-model="collaborateurAddingName"
                :data="collaborateursCanBeAdded"
                :serializer="item => item.full_name"
                @hit="collaborateurAddingObject = $event"
                 placeholder="Chercher votre collaborateur par nom ou prénom">
                 <template slot="append">
                    <CButton
                      :color="addCollaborateurButtonStyle"
                      block class="px-4"
                      @click='addCollaborateur'
                      :disabled="$v.collaborateurAddingObject.$invalid || addCollaborateurInProcess || !mission.is_active">
                      <CSpinner size="sm" label="activate user spinner" v-if="addCollaborateurInProcess"></CSpinner>
                      {{ addCollaborateurButtonText }}
                    </CButton>
                  </template>
              </vue-bootstrap-typeahead>

              </CCol>
            </CRow>
          </CCardBody>
        </CCollapse>
    </CCard>

    <CCard class="mt-3" v-if="isManager">
      <CCardHeader
        @click="cardFacturesCollapse = !cardFacturesCollapse"
        style="padding-bottom: 15px !important; cursor: pointer;"
      >
        <strong>💵 Facturation de la mission</strong>
        <div class="card-header-actions">
          <CIcon v-if="!cardFacturesCollapse" name="cil-arrow-circle-bottom" size="lg"/>
          <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
        </div>
      </CCardHeader>
      <CCollapse :show="cardFacturesCollapse">
        <CCardBody>
          <div v-if="!mission.is_paiement_alert && connectedUserInMissionCollaborateurs" class="card-header-actions">
            <CButton
              @click="setMissionPaiementAlert"
              shape="pill" block class="px-4"
              :disabled="!mission.is_active"
              size="sm" color="outline-danger">
              Lever une alerte de paiement
            </CButton>
          </div>
          <CDataTable
            class="mt-1 cursor-table"
            hover
            :items="facturesRender"
            :fields="facturesRenderFieds"
            @row-clicked="goToFacturePage"
          >

          <template #date_echeance="{item}">
            <td>
             <span>{{$dayjs(item.date_echeance).format('DD/MM/YYYY')}}</span>
            </td>
          </template>

          <template #montant_total_ht="{item}">
            <td>
              {{parseFloat(item.montant_total_ht).toFixed(2)}} €
            </td>
          </template>
          <template #montant_total_ttc="{item}">
            <td>
              {{parseFloat(item.montant_total_ttc).toFixed(2)}} €
            </td>
          </template>
          <template #montant_total_paid="{item}">
            <td>
              {{parseFloat(item.montant_total_paid).toFixed(2)}} €
            </td>
          </template>

          <template #no-items-view>
            <CRow>
              <CCol class="text-center">
                Aucune facture créée
              </CCol>
            </CRow>
          </template>
          </CDataTable>

          <div v-if="company.iban && company.bic && company.titulaire">
            <CButton
              v-if="connectedUserInMissionCollaborateurs"
              @click="$router.push('/add-new-facture/'+$route.params.id)"
              shape="pill" block class="px-4"
              :disabled="!mission.is_active"
              color="outline-success">
              <CIcon name="cil-plus"/> Ajouter une nouvelle facture
            </CButton>
            <CRow v-else>
              <CCol class="text-center">
                <strong class="text-danger"> Vous devez être affecté à cette mission pour pouvoir ajouter des factures </strong>
              </CCol>
            </CRow>
          </div>
          <div class="text-center" v-else>
            <p v-if="userGroupContains(['BOSS'])" class="text-danger">
                Veuillez
                <router-link to="/company/">
                  <strong class="text-danger">renseigner les coordonnées bancaires de votre entreprise </strong>
                </router-link>
                pour pouvoir créer une facture
            </p>

            <p v-else class="text-danger">
                Le responsable de votre compte Eclerk doit renseigner les coordonnées bancaires de votre entreprise
                pour que vous puissiez émettre une facture.
            </p>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>


    <CCard class="mt-3">
      <CCardHeader
        @click="cardDocumentsCollapse = !cardDocumentsCollapse"
        style="padding-bottom: 15px !important; cursor: pointer;"
      >
        <strong>📃 Demandes de documents au client</strong>
        <div class="card-header-actions">
          <CIcon v-if="!cardDocumentsCollapse" name="cil-arrow-circle-bottom" size="lg"/>
          <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
        </div>
      </CCardHeader>
      <CCollapse :show="cardDocumentsCollapse">
        <CCardBody>
          <CDataTable
            class="mt-1 cursor-table"
            hover
            :items="documentAskingsRender"
            :fields="documentAskingsRenderFieds"
            @row-clicked='goToDocumentAskingPage'
          >
          <!--@row-clicked="goToFacturePage"-->
            <template #state="{item}">
              <td>
                {{collaborateurDocumentAskingsStateRender(item.state)}}
              </td>
            </template>

            <template #description="{item}">
              <td>
                <small>{{item.description}}</small>
              </td>
            </template>

            <template #no-items-view>
              <CRow>
                <CCol class="text-center">
                  Aucune demande de documents réalisée
                </CCol>
              </CRow>
            </template>
          </CDataTable>


          <div v-if="mission.client.user">
            <CButton
              v-if="connectedUserInMissionCollaborateurs"
              id="create-new-document-asking-collapse"
              shape="pill" block class="px-4"
              :disabled="!mission.is_active"
              color="outline-dark"
              @click="createDocumentAskingIsCollapsed =! createDocumentAskingIsCollapsed">

              <span v-if="!createDocumentAskingIsCollapsed">
                Faire une nouvelle demande de documents
              </span>
              <span v-else>
                Fermer
              </span>
            </CButton>
            <CRow v-else>
              <CCol class="text-center">
                <strong class="text-danger"> Vous devez être affecté à cette mission pour faire une demande de documents </strong>
              </CCol>
            </CRow>
          </div>
          <div class="text-center" v-else>
            <strong v-if="isManager">
              Vous devez d'abord
              <router-link
                :to="'/create-client-access/' + mission.client.id">
                <span class="text-primary">créer un accès Eclerk à votre client</span>
              </router-link>
              avant de faire une demande de documents
            </strong>

            <strong v-else>
              Un manager doit d'abord créer un compte Eclerk à votre client pour faire une demande de documents
            </strong>
          </div>


          <CCollapse :show="createDocumentAskingIsCollapsed">
            <CCard bodyWrapper>
              <CForm v-on:submit.prevent="createDocumentAsking()">
                <CInput
                  label="Nom"
                  v-model="createDocumentAskingName" type="text"
                  maxlength="150"
                  placeholder="Nom de la demande"
                  @input="$v.createDocumentAskingName.$touch()"
                  :isValid="$v.createDocumentAskingName.$dirty ? !$v.createDocumentAskingName.$error : null"
                  invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                >
                </CInput>
                <CTextarea
                  label="Description des documents (facultatif)"
                  v-model="createDocumentAskingDescription" type="text"
                  rows="3"
                  maxlength="500"
                  placeholder="Description"
                  @input="$v.createDocumentAskingDescription.$touch()"
                  :isValid="$v.createDocumentAskingDescription.$dirty ? !$v.createDocumentAskingDescription.$error : null"
                >
                </CTextarea>

                <CRow class="mt-4">
                  <CCol class="text-center">
                    <CButton
                      type="submit" :color="createDocumentAskingButtonStyle"
                      shape="pill" block class="px-4"
                      :disabled="$v.createDocumentAskingName.$invalid || $v.createDocumentAskingDescription.$învalid || createDocumentAskingInProcess">
                      <CSpinner size="sm" label="create document asking spinner" v-if="createDocumentAskingInProcess"></CSpinner>
                      {{ createDocumentAskingButtonText }}
                    </CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCard>
          </CCollapse>
        </CCardBody>
      </CCollapse>
    </CCard>


    <CCard class="mt-3">
      <CCardHeader
        @click="cardFraisCollapse = !cardFraisCollapse"
        style="padding-bottom: 15px !important; cursor: pointer;"
      >
        <strong>💸 Frais associés à la mission</strong>
        <div class="card-header-actions">
          <CIcon v-if="!cardFraisCollapse" name="cil-arrow-circle-bottom" size="lg"/>
          <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
        </div>
      </CCardHeader>
      <CCollapse :show="cardFraisCollapse">
        <CCardBody>
          <missionFraisComponent
            v-bind:missionPk="$route.params.id"
            v-bind:connectedUserInMissionCollaborateurs="connectedUserInMissionCollaborateurs" />
        </CCardBody>
      </CCollapse>
    </CCard>



    <CRow>
      <CCol lg="6">
        <CCard class="mt-3">
          <CCardHeader
            @click="cardPrioritesCollapse = !cardPrioritesCollapse"
            style="padding-bottom: 15px !important; cursor: pointer;"
          >
            <strong>📌 Priorités de la mission</strong>
            <div class="card-header-actions">
              <CIcon v-if="!cardPrioritesCollapse" name="cil-arrow-circle-bottom" size="lg"/>
              <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
            </div>
          </CCardHeader>
          <CCollapse :show="cardPrioritesCollapse">
            <CCardBody>
              <CCard v-for="priority in priorities" v-bind:key="priority.id" class="priority-card" @click="$router.push('/modify-priority/' + priority.mission + '/' + priority.id)">
                <CCardHeader>
                  <strong>{{priority.name}}</strong>
                  <CBadge color="danger" class="float-right" shape="pill" v-if="priority.priority_state == 'MAJ'">Priorité majeure</CBadge>
                  <CBadge color="secondary" class="float-right" shape="pill" v-if="priority.priority_state == 'MIN'">Priorité mineure</CBadge>
                </CCardHeader>
                <CCardBody>
                  Date d'échéance : <strong>{{$dayjs(priority.ending_date).format('DD/MM/YYYY')}}</strong><br>
                  Référent : <strong>{{priority.referent_full_name}}</strong><br>
                  Collaborateurs associé(s) : <span v-for="collab in priority.collaborateurs" v-bind:key="collab.id">{{collab.full_name}} </span><br>
                  <em>{{priority.description}}</em>
                </CCardBody>
              </CCard>

              <CButton
                v-if="connectedUserInMissionCollaborateurs"
                @click="$router.push('/add-new-priority/'+$route.params.id)"
                shape="pill" block class="px-4"
                :disabled="!mission.is_active"
                color="outline-success">
                <CIcon name="cil-plus" /> Ajouter une nouvelle priorité
              </CButton>
              <CRow v-else>
                <CCol class="text-center">
                  <strong class="text-danger"> Vous devez être affecté à cette mission pour pouvoir ajouter des priorités </strong>
                </CCol>
              </CRow>
            </CCardBody>
          </CCollapse>
        </CCard>
      </CCol>
      <CCol lg="6">
        <CCard class="mt-3">
          <CCardHeader
            @click="cardPrioritesTermineesCollapse = !cardPrioritesTermineesCollapse"
            style="padding-bottom: 15px !important; cursor: pointer;"
          >
            <strong>Priorités de la mission terminées</strong>
            <div class="card-header-actions">
              <CIcon v-if="!cardPrioritesTermineesCollapse" name="cil-arrow-circle-bottom" size="lg"/>
              <CIcon v-else name="cil-arrow-circle-top" size="lg"/>
            </div>
          </CCardHeader>
          <CCollapse :show="cardPrioritesTermineesCollapse">
            <CCardBody>
              <CCard v-for="inactivePriority in inactivePriorities" v-bind:key="inactivePriority.id"
                class="priority-card" @click="$router.push('/modify-priority/' + inactivePriority.mission + '/' + inactivePriority.id)" style="background-color: #F2F2F2;">
                <CCardHeader  style="background-color: #F2F2F2;">
                  <strong>{{inactivePriority.name}}</strong>
                  <CBadge color="danger" class="float-right" shape="pill" v-if="inactivePriority.priority_state == 'MAJ'">Priorité majeure</CBadge>
                  <CBadge color="secondary" class="float-right" shape="pill" v-if="inactivePriority.priority_state == 'MIN'">Priorité mineure</CBadge>
                </CCardHeader>
                <CCardBody>
                  Date d'échéance : <strong>{{$dayjs(inactivePriority.ending_date).format('DD/MM/YYYY')}}</strong><br>
                  Référent : <strong>{{inactivePriority.referent_full_name}}</strong><br>
                  <em>{{inactivePriority.description}}</em>
                </CCardBody>
              </CCard>
            </CCardBody>
          </CCollapse>
        </CCard>


      </CCol>
    </CRow>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
import Loading from 'vue-loading-overlay';

import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

import missionFraisComponent from '@/components/fraisComponents/missionFraisComponent'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import 'vue-loading-overlay/dist/vue-loading.css';


const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()


export default {
  name: 'MissionProfile',
  components: {
    Loading,
    VueBootstrapTypeahead,
    missionFraisComponent
  },
  mixins: [
    validationMixin,
    renderMixins,
    userMixins
  ],
  data: function () {
    return {

      // ------------- CARD COLLAPSE -----
      cardDescriptionCollapse: true,
      cardCollaborateurCollapse: false,
      cardFacturesCollapse: false,
      cardFraisCollapse: false,
      cardDocumentsCollapse: false,
      cardPrioritesCollapse: false,
      cardPrioritesTermineesCollapse: false,

      // ------------- MISSION -----------
      mission: {
        id: 0,
        name: '',
        client: {
          name: '',
          is_different_payeur: false,
          payeur_name: ''
        },
        types: [],
        state: '',
        montant_ht: '',
        starting_date: '',
        ending_date: '',
        description: '',
        is_active: '',
        created_at: '',

        contact: {
          id: 0,
          first_name: '',
          last_name: '',
          full_name: '',
        },
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        collaborateurs: [],
        convention_pk: null,
      },
      isMissionLoading: false,
      missionCollaborateursRender: [],
      missionCollaborateursUsernamesSet: new Set([]),
      missionCollaborateursRenderFields: [
        { key: "full_name", label: "Collaborateur", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
      ],

      // ------------- COMPANY -----------
      company: {
        company_name: '',
        siret: '',
        forme_sociale: '',
        capital: 0,
        address: '',
        phone_country_code: '',
        phone_number: '',
        email: '',
        iban: '',
        bic: '',
        titulaire: ''
      },
      isCompanyLoading: false,

      // ------------- ACTIVATE MISSION -------------
      activateMissionInProcess: false,
      activateMissionButtonText: "Réactiver cette mission",
      activateMissionButtonStyle: 'primary',
      isMissionActivated: false,

      // --------------- PRIORITIES --------------
      isAllPrioritiesLoading: false,
      priorities: [],

      // --------------- INACTIVE PRIORITIES --------------
      isAllInactivePrioritiesLoading: false,
      inactivePriorities: [],

      // --------------- PAIEMENTS --------------
      isAllFacturesLoading: false,
      factures: [],
      facturesRender: [],
      facturesRenderFieds: [
        { key: "facture_number", label: "#", tdClass: 'ui-helper-center', sortable: true},
        { key: "facture_title", label: "Intitulé", tdClass: 'ui-helper-center', sortable: true},
        { key: "date_echeance", label: "Date d'échéance", tdClass: 'ui-helper-center'},
        { key: "montant_total_ht", label: "Montant total HT", tdClass: 'ui-helper-center'},
        { key: "montant_total_ttc", label: "Montant total TTC", tdClass: 'ui-helper-center'},
        { key: "montant_total_paid", label: "Montant payé", tdClass: 'ui-helper-center'},
      ],

      // -------------- COLLABORATEURS ----------
      isAllCollaborateursLoading: false,
      collaborateurs: [],
      collaborateurAddingName: '',
      collaborateurAddingObject: {
        id: '',
        full_name: ''
      },

      addCollaborateurButtonText: 'Affecter ce collaborateur',
      addCollaborateurButtonStyle: 'outline-dark',
      addCollaborateurInProcess: false,

      isCollaborateurRemoving: false,
      isCollaborateurRemovingReferent: false,
      collaborateurRemovingId: 0,
      collaborateurRemovingFullName: '',
      removeCollaborateurButtonText: 'Retirer ce collaborateur de la mission',
      removeCollaborateurButtonStyle: 'outline-danger',
      removeCollaborateurInProcess: false,


      // ------------- DOCUMENT ASKING --------------
      isAllDocumentAskingsLoading: false,
      documentAskings: [],
      documentAskingsRender: [],
      documentAskingsRenderFieds: [
        { key: "name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center', sortable: true},
        { key: "description", label: "Description", tdClass: 'ui-helper-center'},
      ],

      createDocumentAskingIsCollapsed: false,
      createDocumentAskingName: '',
      createDocumentAskingDescription: '',
      isDocumentAskingCreated: false,

      createDocumentAskingButtonText: 'Faire une demande de documents',
      createDocumentAskingButtonStyle: 'outline-dark',
      createDocumentAskingInProcess: false,

      isDeleteDocumentAsking: false,
      deleteDocumentAskingId: '',
      isDocumentAskingDeleted: false,

      deleteDocumentAskingButtonText: 'Confirmer la suppression',
      deleteDocumentAskingButtonStyle: 'outline-danger',
      deleteDocumentAskingInProcess: false,

      createConventionInProcess: false,

      // ------ STATISTICS
      nbFacturesToPay: 0,
      resteAPayer: 0
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isMissionLoading || this.isAllCollaborateursLoading || this.isAllPrioritiesLoading || this.isAllFacturesLoading || this.isAllDocumentAskingsLoading || this.isCompanyLoading || this.createConventionInProcess) {
        return true
      }
      return false
    },
    collaborateursCanBeAdded () {
      var collaborateurToAddArray = []
      for (var i = 0; i < this.collaborateurs.length; i++) {
        if (!this.missionCollaborateursUsernamesSet.has(this.collaborateurs[i]['user']['username'])) {
          collaborateurToAddArray.push({
            'id': this.collaborateurs[i].id,
            'full_name': this.collaborateurs[i].full_name
          })
        }
      }
      return collaborateurToAddArray
    },
    connectedUserInMissionCollaborateurs () {
      var connectedUserUsername = this.$store.getters.getUserUsername
      if (this.missionCollaborateursUsernamesSet.has(connectedUserUsername)) {
        return true
      }
      else {
        return false
      }
    },

    isManager () {
      if (this.userGroupContains(['MANAGER'])) {
        return true
      }
      else {
        return false
      }
    }
  },
  validations: {
    collaborateurAddingObject: {
      id: {
        required
      }
    },
    createDocumentAskingName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(150)
    },
    createDocumentAskingDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(500)
    }
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getMission()
    this.getCompany()
    this.getAllMissionPriorities()
    this.getAllMissionInactivePriorities()
    this.getAllCollaborateurs()
    this.getAllMissionDocumentAskings()

    if (this.isManager) {
      this.getAllMissionFactures()
    }

  },

  watch: {
    mission: function (newMission) {
      var missionCollabs = newMission.collaborateurs
      this.missionCollaborateursUsernamesSet = new Set([])
      if (missionCollabs.length == 0) {
        this.missionCollaborateursRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < missionCollabs.length; i++) {
          final_array.push(
            {
              'id': missionCollabs[i].id,
              'full_name': missionCollabs[i].full_name,
              'email': missionCollabs[i].user.username,
              'phone_country_code': missionCollabs[i].phone_country_code,
              'phone_number': missionCollabs[i].phone_number,
            }
          )
          this.missionCollaborateursUsernamesSet.add(missionCollabs[i].user.username)
        }
        this.missionCollaborateursRender = final_array
      }
    },
    factures: function (newFactures) {
      // PAIEMENT DETAILLED
      this.facturesRender = []

      for (var i = 0; i < newFactures.length; i++) {
        var temp_data = {
          'id': newFactures[i].id,
          'facture_number': newFactures[i].facture_number,
          'facture_title': newFactures[i].facture_title,
          'date_echeance': newFactures[i].date_echeance,
          'montant_total_ht': newFactures[i].montant_total_ht,
          'montant_total_ttc': newFactures[i].montant_total_ttc,
          'montant_total_paid': newFactures[i].montant_total_paid,
        }

        var today = this.$dayjs()
        var echeanceDate = this.$dayjs(newFactures[i].date_echeance)
        if (parseFloat(newFactures[i].montant_total_paid).toFixed(2) == parseFloat(newFactures[i].montant_total_ttc).toFixed(2)) {
          temp_data['_classes'] = 'table-info'
        }
        else if (today > echeanceDate) {
          temp_data['_classes'] = 'table-danger'
          this.nbFacturesToPay ++
          this.resteAPayer = parseFloat(this.resteAPayer) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        else {
          this.nbFacturesToPay ++
          this.resteAPayer = parseFloat(this.resteAPayer) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        this.facturesRender.push(temp_data)
      }

    },

    documentAskings: function (newDocumentAskings) {
      if (newDocumentAskings.length == 0) {
        this.documentAskingsRender = []
      }
      else {
        this.documentAskingsRender = []

        for (var i = 0; i < newDocumentAskings.length; i++) {
          var temp_data = {
            'id': newDocumentAskings[i].id,
            'name': newDocumentAskings[i].name,
            'state': newDocumentAskings[i].state,
            'description': newDocumentAskings[i].description,
          }

          if (newDocumentAskings[i].state == 'VALIDATED') {
            temp_data['_classes'] = 'table-success'
          }
          else if (newDocumentAskings[i].state == 'REFUSED') {
            temp_data['_classes'] = 'table-danger'
          }

          this.documentAskingsRender.push(temp_data)

        }
      }
    },

    collaborateurAddingName(newQuery) {
      if (newQuery == '') {
        this.collaborateurAddingObject = {
          id: '',
          full_name: ''
        }
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getMission () {
      this.isMissionLoading = true
      apiUserConnected.getMission(this.token, this.$route.params.id)
      .then((result) => {
        this.mission = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionLoading = false
      })
    },
    getCompany () {
      this.isCompanyLoading = true
      apiUserConnected.getCompany(this.token)
      .then((result) => {
        this.company = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCompanyLoading = false
      })
    },
    getAllCollaborateurs () {
      this.isAllCollaborateursLoading = true
      apiUserConnected.getAllCollaborateurs(this.token)
      .then((result) => {
        this.collaborateurs = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateursLoading = false
      })
    },
    getAllMissionPriorities () {
      this.isAllPrioritiesLoading = true
      apiManagerConnected.getAllMissionPriorities(this.token, this.$route.params.id)
      .then((result) => {
        this.priorities = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllPrioritiesLoading = false
      })
    },
    getAllMissionInactivePriorities () {
      this.isAllInactivePrioritiesLoading = true
      apiManagerConnected.getAllMissionInactivePriorities(this.token, this.$route.params.id)
      .then((result) => {
        this.inactivePriorities = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllInactivePrioritiesLoading = false
      })
    },
    getAllMissionFactures () {
      this.isAllFacturesLoading = true
      apiManagerConnected.getAllMissionFactures(this.token, this.$route.params.id)
      .then((result) => {
        this.factures = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllFacturesLoading = false
      })
    },

    getAllMissionDocumentAskings () {
      this.isAllDocumentAskingsLoading = true
      apiUserConnected.getAllMissionDocumentAskings(this.token, this.$route.params.id)
      .then((result) => {
        this.documentAskings = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentAskingsLoading = false
      })
    },

    // --------------- ACTIVATE MISSION ----------------
    activateMission () {
      this.activateMissionInProcess = true
      this.activateMissionButtonText = "Activation en cours"
      this.activateMissionButtonStyle = 'secondary'
      apiManagerConnected.activateMission(this.token, this.$route.params.id)
      .then(() => {
        this.getMission()
        this.isMissionActivated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activateMissionInProcess = false
        this.activateMissionButtonText = "Réactiver la mission"
        this.activateMissionButtonStyle = 'outline-dark'
      })
    },

    // ------- Handle Collaborateurs ------
    addCollaborateur () {
      this.addCollaborateurInProcess = true
      apiManagerConnected.addCollaborateurToMission(this.token, this.$route.params.id, this.collaborateurAddingObject.id)
      .then(() => {
        this.getMission()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addCollaborateurInProcess = false
      })
    },

    clickOnMissionCollaborateur (item) {
      this.collaborateurRemovingId = item.id
      this.collaborateurRemovingFullName = item.full_name
      this.isCollaborateurRemoving = true
    },

    removeCollaborateur () {
      this.removeCollaborateurInProcess = true
      if (this.collaborateurRemovingId == this.mission.referent.id) {
        this.isCollaborateurRemoving = false
        this.removeCollaborateurInProcess = false
        this.isCollaborateurRemovingReferent = true
      }
      else {
        apiManagerConnected.removeCollaborateurFromMission(this.token, this.$route.params.id, this.collaborateurRemovingId)
        .then(() => {
          this.getMission()
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isCollaborateurRemoving = false
          this.removeCollaborateurInProcess = false
        })
      }
    },

    // -------- ALERT PAIEMENT SETTERS ------------
    setMissionPaiementAlert () {
      apiUserConnected.setMissionPaiementAlert(this.token, this.$route.params.id)
      .then(() => {
        this.getMission()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    removeMissionPaiementAlert () {
      apiUserConnected.removeMissionPaiementAlert(this.token, this.$route.params.id)
      .then(() => {
        this.getMission()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    // ------------ DOCUMENT ASKING FUNCTIONS ----------
    createDocumentAsking () {
      this.createDocumentAskingInProcess = true
      this.createDocumentAskingButtonText = "Création en cours"
      this.createDocumentAskingButtonStyle = 'secondary'
      apiUserConnected.createDocumentAsking(this.token, this.$route.params.id, this.createDocumentAskingName, this.createDocumentAskingDescription)
      .then(() => {
        this.isDocumentAskingCreated = true
        this.createDocumentAskingIsCollapsed = false
        this.getAllMissionDocumentAskings()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.createDocumentAskingInProcess = false
        this.createDocumentAskingButtonText = "Faire une nouvelle demand de documents"
        this.createDocumentAskingButtonStyle = 'outline-dark'
      })
    },


    // ------------ CONVENTIONS -----------------
    createConventionHonoraires () {
      this.createConventionInProcess = true
      apiManagerConnected.createConventionHonoraires(this.token, this.$route.params.id)
      .then((result) => {
        this.$router.push('/convention-honoraires/' + result.data.id)
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.createConventionInProcess = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToMissionPage() {
      this.$router.push('/manager-modify-mission/' + this.mission.id)
    },

    goToFacturePage(item) {
      this.$router.push({ name: 'Votre facture', params: { facture_pk: item.id } })
    },

    goToDocumentAskingPage(item) {
      this.$router.push('/mission-documents/' + item.id)
    },

    goToReportingPage() {
      var routeUrl = this.$router.resolve({
             path: '/manager-dashboard-tasks/' + String(this.mission.client.id) + '/' + String(this.mission.id),
      });
      window.open(routeUrl .href, '_blank');
    },

    goToConventionsHonoraires () {
      this.$router.push('/convention-honoraires/' + this.mission.convention_pk)
    },
  }
}
</script>

<style>
.autocomplete__icon {
    height: unset;
    width: 14px;
}
.priority-card {
  cursor: pointer;
}
.priority-card:hover {
  background-color: #EFEFFB !important;
}
</style>
